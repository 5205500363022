import React from "react"
import { CLOUDINARY_URL } from "../../constants"

import "./iconParagraph.scss"

const ContentBodyIconParagraph = ({ icon, richText }) => {
  const iconUrl = `${CLOUDINARY_URL}/fl_sanitize/${icon[0].filename}`
  return (
    <div className="content-block content-block--icon">
      {icon.length > 0 && (
        <object type="image/svg+xml" data={iconUrl}>
          <img
            src={iconUrl}
            alt={
              icon[0].imageCaption
                ? icon[0].imageCaption.content
                : icon[0].title
            }
          />
        </object>
      )}
      <div
        className="content-block--text"
        dangerouslySetInnerHTML={{ __html: richText.content }}
      />
    </div>
  )
}

export default ContentBodyIconParagraph
