import React, { useState } from "react"
import { graphql, Link, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import * as moment from "moment"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import PageHeader from "../components/pageHeader"
import ContentBodyText from "../components/contentBody/text"
import ContentBodyIconParagraph from "../components/contentBody/iconParagraph"
import ContentBodyImageWithCaption from "../components/contentBody/imageWithCaption"
import ContentBodyImage from "../components/contentBody/image"
import { CopyToClipboard } from "react-copy-to-clipboard"

import "./openMicPost.scss"
import LinkedInIcon from "../images/icon-linkedin.svg"
import NavigationArrowWhiteIcon from "../images/navigation_arrow--white.svg"

const ContentBlocks = {
  Craft_ContentBodyText: ContentBodyText,
  Craft_ContentBodyIconParagraph: ContentBodyIconParagraph,
  Craft_ContentBodyImageWithCaption: ContentBodyImageWithCaption,
  Craft_ContentBodyImage: ContentBodyImage,
}

const Meta = ({ postDate, authors }) => {
  const dateTimeStamp = new Date(postDate * 1000)
  return (
    <div className="post__meta">
      <time
        className="post__date"
        dateTime={moment(dateTimeStamp).format("YYYY-MM-DD")}
      >
        {moment(dateTimeStamp).format("MMMM YYYY")}
      </time>
      {authors.length > 0 &&
        authors.map(({ node }) => {
          const author = node
          return (
            <div key={`author-${author.id}`} className="post__byline">
              By {author.name}{" "}
              {author.linkedinUrl && (
                <a
                  href={author.linkedinUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="post__author-link"
                >
                  <LinkedInIcon />
                </a>
              )}
            </div>
          )
        })}
    </div>
  )
}

const OpenMicPost = ({ data, location }) => {
  const {
    title,
    subtitle,
    authors,
    postDate,
    heroImage,
    color,
    contentBody,
  } = data.craft.entry
  const titleCheckPunctuation = () => {
    if (title.includes(":")) {
      return title.split(":").join(":\n")
    }
    return title
  }
  const [copied, setCopied] = useState(false)
  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: "post blog" }} />
      <SEO title={`${title} | Open Mic`} location={location} />
      <div className="layout__header-block">
        <Link to="/open-mic" className="open-mic--logo">
          <img
            src={withPrefix("/images/open-mic/open-mic-brand.png")}
            alt="Open Mic Logo"
          />
        </Link>
      </div>
      <PageHeader heroImage={heroImage} color={color}>
        <>
          {postDate && authors && authors.edges.length > 0 && (
            <Meta postDate={postDate} authors={authors.edges} />
          )}
          <h1 className={title.includes(":") ? "break-word" : ""}>
            {titleCheckPunctuation()}
          </h1>
          {subtitle ? <h2>{subtitle}</h2> : null}
        </>
      </PageHeader>

      <section className="layout__section layout__article">
        <div className="layout__main">
          <div className="layout__inner layout__inner--full-width">
            {contentBody.length > 0 &&
              contentBody.map(block => {
                const BlockComponent = ContentBlocks[block.__typename]
                return (
                  <BlockComponent
                    key={`contentBlock-${block.__typename.toLowerCase()}-${
                      block.id
                    }`}
                    {...block}
                  />
                )
              })}
          </div>

          <div className="btns-group">
            <div className="btns-group__left-btns">
              <Link
                to="/open-mic"
                className="btn btn--back btn--solid btn--accent"
              >
                <NavigationArrowWhiteIcon alt="Arrow" /> More Stories
              </Link>
            </div>
            <div className="btns-group__right-btns">
              <CopyToClipboard
                text={location.href}
                onCopy={() => setCopied(true)}
              >
                <button type="button" className="btn btn--gray-medium">
                  {!copied ? "Share" : "Copied"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const getOpenMicPostQuery = graphql`
  query($slug: String!) {
    craft {
      entry(section: [openMicPosts], slug: $slug) {
        ... on Craft_OpenMicPosts {
          title
          subtitle
          postDate
          authors: openMicPostAuthorConnection {
            edges {
              node {
                ... on Craft_Authors {
                  id
                  name: title
                  linkedinUrl
                }
              }
            }
          }
          heroImage {
            ... on Craft_HeroVolume {
              filename
              focalPoint {
                x
                y
              }
              title
              heroImageSize
              heroImageSizeModifier
            }
          }
          color
          contentBody {
            ... on Craft_ContentBodyText {
              __typename
              id
              richText {
                content
              }
            }
            ... on Craft_ContentBodyIconParagraph {
              __typename
              id
              icon {
                ... on Craft_IconsVolume {
                  filename
                  title
                }
              }
              richText {
                content
              }
            }
            ... on Craft_ContentBodyImageWithCaption {
              __typename
              id
              image {
                ... on Craft_CloudImagesVolume {
                  id
                  imageCaption {
                    content
                  }
                  filename
                  focalPoint {
                    x
                    y
                  }
                  title
                }
              }
              richText {
                content
              }
            }
            ... on Craft_ContentBodyImage {
              __typename
              id
              image {
                ... on Craft_CloudImagesVolume {
                  id
                  imageCaption {
                    content
                  }
                  filename
                  focalPoint {
                    x
                    y
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

export default OpenMicPost
